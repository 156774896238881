import moment from 'moment-timezone';

const DEFAULT_DATE_DISPLAY_FORMAT = 'DD MMM YYYY' as const;

export function formatDate(date: string, timezone?: string, format?: string) {
    const utcTime = moment.utc(date);

    let localTime = utcTime.clone();
    if (timezone) {
        localTime = localTime.tz(timezone);
    }

    return localTime.format(format ?? DEFAULT_DATE_DISPLAY_FORMAT);
}
